import styled from '@emotion/styled';

import Color from '~/constants/Color';

export const TextBase = styled.div<{
  color?: string;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
}>`
  color: ${({ color = Color.C000000 }) => color};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const Text48Bold = styled(TextBase)`
  font-size: 48px;
  font-weight: 500;
`;

export const Text48 = styled(TextBase)`
  font-size: 48px;
`;

export const Text32Bold = styled(TextBase)`
  font-size: 32px;
  font-weight: 500;
`;

export const Text32 = styled(TextBase)`
  font-size: 32px;
`;

export const Text20Bold = styled(TextBase)`
  font-size: 20px;
  font-weight: 500;
`;

export const Text20 = styled(TextBase)`
  font-size: 20px;
`;

export const Text16Bold = styled(TextBase)`
  font-size: 16px;
  font-weight: 500;
`;

export const Text16 = styled(TextBase)`
  font-size: 16px;
`;

export const Text12Bold = styled(TextBase)`
  font-size: 12px;
  font-weight: 500;
`;

export const Text12 = styled(TextBase)`
  font-size: 12px;
`;

export const Text8Bold = styled(TextBase)`
  font-size: 8px;
  font-weight: 500;
`;

export const Text8 = styled(TextBase)`
  font-size: 8px;
`;

export const TextRadiusWrapper = styled.div<{
  paddingVertical?: number;
  paddingHorizontal?: number;
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor = Color.CFFFFFF }) => backgroundColor};
  padding: ${({ paddingVertical = 6, paddingHorizontal = 16 }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-radius: 16px;
  justify-content: center;
`;
