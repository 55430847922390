import { Checkbox, Form, Image, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  useCreateCategoryMutation,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from '~/api/CategoryAPI';
import { CategoryDto } from '~/classes/dto/CategoryDto';
import useS3Handler from '~/hooks/useS3Handler';
import { Column } from '~/styles/Wrappers';

interface Props {
  categoryId: string;
  categoryModalOpen: boolean;
  setCategoryModalOpen: (categoryModalOpen: boolean) => void;
}

const CategoryModal = ({ categoryId, categoryModalOpen, setCategoryModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<CategoryDto>({
    initialValues: {
      title: '',
      category: '',
      description: '',
      image: '',
      expandedImage: '',
      disabled: false,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
      category: Yup.string().required(),
      description: Yup.string().required(),
      image: Yup.string().required(),
      expandedImage: Yup.string().required(),
      disabled: Yup.boolean().required(),
    }),
    onSubmit: async (data: CategoryDto) => {
      setIsConfirmLoading(true);

      if (categoryId) {
        await updateCategory({ ...data, _id: categoryId });
      } else {
        await createCategory(data);
      }

      setIsConfirmLoading(false);
      setCategoryModalOpen(false);
    },
  });

  const { data } = useGetCategoryQuery(categoryId);
  const [createCategory] = useCreateCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();

  const getFileName = useS3Handler();

  const handleS3Upload = async (event) => {
    const fileName = await getFileName(event, 'category/');

    setFieldValue(event.target.name, fileName);
  };

  useEffect(() => {
    if (!categoryId) return resetForm();

    if (data) {
      setFieldValue('category', data.category);
      setFieldValue('title', data.title);
      setFieldValue('description', data.description);
      setFieldValue('image', data.image);
      setFieldValue('expandedImage', data.expandedImage);
      setFieldValue('disabled', data.disabled);
    }
  }, [categoryId, data, resetForm, setFieldValue]);

  return (
    <Modal
      title={categoryId ? '카테고리 수정' : '새로운 카테고리 생성'}
      open={categoryModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setCategoryModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="타이틀">
          <Input name="title" value={values.title} onChange={handleChange} />
        </Form.Item>

        <Form.Item label="카테고리">
          <Input name="category" value={values.category} onChange={handleChange} />
        </Form.Item>

        <Form.Item label="설명">
          <TextArea
            name="description"
            value={values.description}
            onChange={handleChange}
            rows={4}
          />
        </Form.Item>

        <Form.Item label="이미지">
          <Column>
            {values.image && (
              <Image
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_S3_URL}${values.image}`}
                alt={values.image}
              />
            )}
            <input type="file" accept="image/*" name="image" onChange={handleS3Upload} />
          </Column>
        </Form.Item>

        <Form.Item label="확장된 이미지">
          <Column>
            {values.expandedImage && (
              <Image
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_S3_URL}${values.expandedImage}`}
                alt={values.expandedImage}
              />
            )}
            <input type="file" accept="image/*" name="expandedImage" onChange={handleS3Upload} />
          </Column>
        </Form.Item>

        <Form.Item label="비활성화" valuePropName="checked">
          <Checkbox name="disabled" checked={values.disabled} onChange={handleChange}>
            비활성화
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryModal;
