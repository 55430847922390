import { Form, Input, Modal, Select } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useGetUserQuery, useUpdateUserMutation } from '~/api/UserAPI';
import { UserType, UserTypeText, GenderText, Gender } from '~/classes/User';
import { UserDto } from '~/classes/dto/UserDto';

interface Props {
  userId: string;
  userModalOpen: boolean;
  setUserModalOpen: (userModalOpen: boolean) => void;
}

const UserModal = ({ userId, userModalOpen, setUserModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik<UserDto>({
    initialValues: {
      userId,
      userType: UserType.ROAMER,
    },
    validationSchema: Yup.object().shape({
      userId: Yup.string().required(),
      userType: Yup.mixed<UserType>().oneOf(Object.values(UserType)).required(),
    }),
    onSubmit: async (data: UserDto) => {
      setIsConfirmLoading(true);

      await updateUser(data);

      setIsConfirmLoading(false);
      setUserModalOpen(false);
    },
  });

  const { data } = useGetUserQuery(userId);
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (data) {
      setFieldValue('userId', data._id);
      setFieldValue('userType', data.userType);
    }
  }, [userId, data, setFieldValue]);

  return (
    <Modal
      title="유저 수정"
      open={userModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setUserModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="닉네임">
          <Input name="nickname" value={data?.nickname} onChange={handleChange} disabled />
        </Form.Item>

        <Form.Item label="이메일">
          <Input name="email" value={data?.email} onChange={handleChange} disabled />
        </Form.Item>

        <Form.Item label="성별">
          <Input
            name="gender"
            value={GenderText[data?.gender || Gender.FEMALE]}
            onChange={handleChange}
            disabled
          />
        </Form.Item>

        <Form.Item label="유저 타입">
          <Select
            className="userType"
            value={values.userType}
            onChange={(userType) => setFieldValue('userType', userType)}
            options={Object.values(UserType).map((userType) => ({
              value: userType,
              label: UserTypeText[userType],
            }))}
          />
        </Form.Item>

        <Form.Item label="디비이스 이름">
          <Input name="device" value={data?.device?.deviceName} onChange={handleChange} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserModal;
