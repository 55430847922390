import { Checkbox, Form, Image, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  useCreateProgramMutation,
  useGetProgramQuery,
  useUpdateProgramMutation,
} from '~/api/ProgramAPI';
import { ProgramDto } from '~/classes/dto/ProgramDto';
import useS3Handler from '~/hooks/useS3Handler';
import { Column } from '~/styles/Wrappers';

interface Props {
  categoryId: string;
  programId: string;
  programModalOpen: boolean;
  setProgramModalOpen: (programModalOpen: boolean) => void;
}

const ProgramModal = ({ categoryId, programId, programModalOpen, setProgramModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<ProgramDto>({
    initialValues: {
      categoryId,
      title: '',
      description: '',
      narrator: '',
      image: '',
      disabled: false,
    },
    validationSchema: Yup.object().shape({
      categoryId: Yup.string().required(),
      title: Yup.string().required(),
      description: Yup.string().required(),
      narrator: Yup.string().required(),
      image: Yup.string().required(),
      disabled: Yup.boolean().required(),
    }),
    onSubmit: async (data: ProgramDto) => {
      setIsConfirmLoading(true);

      if (programId) {
        await updateProgram({ ...data, _id: programId });
      } else {
        await createProgram(data);
      }

      setIsConfirmLoading(false);
      setProgramModalOpen(false);
    },
  });

  const { data } = useGetProgramQuery(programId);
  const [createProgram] = useCreateProgramMutation();
  const [updateProgram] = useUpdateProgramMutation();

  const getFileName = useS3Handler();

  const handleS3Upload = async (event) => {
    const fileName = await getFileName(event, 'program/');

    setFieldValue(event.target.name, fileName);
  };

  useEffect(() => {
    if (!programId) return resetForm();

    if (data) {
      setFieldValue('categoryId', data.categoryId);
      setFieldValue('title', data.title);
      setFieldValue('description', data.description);
      setFieldValue('narrator', data.narrator);
      setFieldValue('image', data.image);
      setFieldValue('disabled', data.disabled);
    }
  }, [data, programId, resetForm, setFieldValue]);

  return (
    <Modal
      title={programId ? '프로그램 수정' : '새로운 프로그램 생성'}
      open={programModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      onCancel={() => setProgramModalOpen(false)}
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="타이틀">
          <Input name="title" value={values.title} onChange={handleChange} />
        </Form.Item>

        <Form.Item label="설명">
          <TextArea
            name="description"
            value={values.description}
            onChange={handleChange}
            rows={4}
          />
        </Form.Item>

        <Form.Item label="나레이터">
          <Input name="narrator" value={values.narrator} onChange={handleChange} />
        </Form.Item>

        <Form.Item label="이미지">
          <Column>
            {values.image && (
              <Image
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_S3_URL}${values.image}`}
                alt={values.image}
              />
            )}
            <input type="file" accept="image/*" name="image" onChange={handleS3Upload} />
          </Column>
        </Form.Item>

        <Form.Item label="비활성화" valuePropName="checked">
          <Checkbox name="disabled" checked={values.disabled} onChange={handleChange}>
            비활성화
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProgramModal;
