import { Text48Bold } from '~/styles/Texts';
import { Center } from '~/styles/Wrappers';

const Home = () => {
  return (
    <Center flex={1}>
      <Text48Bold>Shine</Text48Bold>
    </Center>
  );
};

export default Home;
