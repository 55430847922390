import { Tag } from 'antd';

import Color from '~/constants/Color';

interface Props {
  disabled: boolean;
}
const DisabledTag = ({ disabled }: Props) => {
  return <Tag color={disabled ? Color.CFF453A : Color.C46B721}>{disabled ? '비활성' : '활성'}</Tag>;
};

export default DisabledTag;
