export default {
  // without authentication
  SIGN_IN: '/sign-in',

  // with authentication
  HOME: '/',
  USERS: '/users',
  CATEGORIES: '/categories',
  PROGRAMS: '/categories/:categoryId/programs',
  SESSIONS: '/categories/:categoryId/programs/:programId/sessions',
  DEVICES: '/devices',
};
