import { Token } from '~/classes/Token';
import { User } from '~/classes/User';
import { SignInDto } from '~/classes/dto/SignInDto';
import axios from '~/helper/axios';

const signIn = async (signInDto: SignInDto): Promise<{ user: User; token: Token }> => {
  const { data } = await axios.post<{ user: User; token: Token }>('/auth/firebase/sign', signInDto);

  return { user: new User(data.user), token: data.token };
};

const load = async (): Promise<User> => {
  const { data } = await axios.get<User>('/auth/load');

  return new User(data);
};

export default { signIn, load };
