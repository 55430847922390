export class Device {
  _id: string;
  deviceName: string;
  android?: string;
  ios?: string;
  windows?: string; // unused, 타입에러로 인해 임시로 추가함
  macos?: string; // unused, 타입에러로 인해 임시로 추가함
  web?: string; // unused, 타입에러로 인해 임시로 추가함
  iosUniqueIDs: { [key: string]: string } = {};
  createdAt: Date;
  updatedAt: Date;

  constructor(device: Device) {
    this._id = device._id;
    this.deviceName = device.deviceName;
    this.android = device.android;
    this.ios = device.ios;
    this.iosUniqueIDs = device.iosUniqueIDs || {};
    this.createdAt = new Date(device.createdAt);
    this.updatedAt = new Date(device.updatedAt);

    return this;
  }
}
