import { Category } from './Category';
import { UserType } from './User';

export class Program {
  _id: string;
  categoryId: string;
  category?: Category;
  title: string;
  description: string;
  narrator: string;
  duration: number;
  image: string;
  userTypes: UserType[];
  disabled: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(program: Program) {
    this._id = program._id;
    this.categoryId = program.categoryId;
    this.category = program.category && new Category(program.category);
    this.title = program.title;
    this.description = program.description;
    this.narrator = program.narrator;
    this.duration = program.duration;
    this.image = program.image;
    this.userTypes = program.userTypes || [];
    this.disabled = program.disabled;
    this.createdAt = new Date(program.createdAt);
    this.updatedAt = new Date(program.updatedAt);
  }
}
