import { CrownOutlined, HeartOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import SiderDefault from 'antd/es/layout/Sider';
import Menu from 'antd/es/menu';
import { NavLink, useLocation } from 'react-router-dom';

import Color from '~/constants/Color';
import Path from '~/constants/Path';

type MenuItem = {
  key: string;
  label: React.ReactNode;
  icon: React.ReactNode;
};

const items: MenuItem[] = [
  {
    key: Path.HOME,
    label: <NavLink to={Path.HOME}>홈</NavLink>,
    icon: <HomeOutlined />,
  },
  {
    key: Path.USERS,
    label: <NavLink to={Path.USERS}>유저</NavLink>,
    icon: <UserOutlined />,
  },
  {
    key: Path.DEVICES,
    label: <NavLink to={Path.DEVICES}>디바이스</NavLink>,
    icon: <CrownOutlined />,
  },
  {
    key: Path.CATEGORIES,
    label: <NavLink to={Path.CATEGORIES}>콘텐츠</NavLink>,
    icon: <HeartOutlined />,
  },
];

const Sider = () => {
  const location = useLocation();

  return (
    <SiderDefault style={{ backgroundColor: Color.CFFFFFF }} width="20%">
      <Menu selectedKeys={[location.pathname]} items={items} />
    </SiderDefault>
  );
};

export default Sider;
