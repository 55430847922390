import { Button } from 'antd';
import { useCallback } from 'react';

import Color from '~/constants/Color';
import { useDispatch } from '~/hooks/useRedux';
import { signIn } from '~/reducers/auth';
import { Text48Bold } from '~/styles/Texts';
import { Center } from '~/styles/Wrappers';

const SignIn = () => {
  const dispatch = useDispatch();

  const handleSignIn = useCallback(() => {
    dispatch(signIn());
  }, [dispatch]);

  return (
    <Center flex={1} backgroundColor={Color.CFF453A}>
      <Text48Bold color={Color.CFFFFFF}>Shine</Text48Bold>
      <Button onClick={handleSignIn}>Google로 로그인</Button>
    </Center>
  );
};

export default SignIn;
