import { createApi } from '@reduxjs/toolkit/query/react';

import Pagination from '~/classes/Pagination';
import { Session } from '~/classes/Session';
import { SearchSessionDto } from '~/classes/dto/SearchSessionDto';
import { SessionDto } from '~/classes/dto/SessionDto';
import { axiosBaseQuery } from '~/helper/axios';

export const sessionAPI = createApi({
  reducerPath: 'sessionAPI',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSession: builder.query<Session, string>({
      query: (_id) => ({ url: `/session/${_id}`, method: 'get' }),
      transformResponse: (session: Session) => new Session(session),
    }),
    getSessions: builder.query<Pagination<Session>, SearchSessionDto>({
      query: (params) => ({ url: '/session', method: 'get', params }),
      transformResponse: (data: Pagination<Session>) => new Pagination(data, Session),
    }),
    createSession: builder.mutation<void, SessionDto>({
      query: (data) => ({ url: '/session/admin', method: 'post', data }),
    }),
    updateSession: builder.mutation<void, SessionDto>({
      query: (data) => ({ url: '/session/admin', method: 'patch', data }),
    }),
    deleteSession: builder.mutation<void, string>({
      query: (_id) => ({ url: `/session/admin/${_id}`, method: 'delete' }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetSessionQuery,
  useGetSessionsQuery,
  useCreateSessionMutation,
  useUpdateSessionMutation,
  useDeleteSessionMutation,
} = sessionAPI;

export default {};
