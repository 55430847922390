export class Category {
  _id: string;
  category: string;
  title: string;
  description: string;
  image: string;
  expandedImage: string;
  disabled: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(category: Category) {
    this._id = category._id;
    this.category = category.category;
    this.title = category.title;
    this.description = category.description;
    this.image = category.image;
    this.expandedImage = category.expandedImage;
    this.disabled = category.disabled;
    this.createdAt = new Date(category.createdAt);
    this.updatedAt = new Date(category.updatedAt);
  }
}
