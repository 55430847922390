import { Token } from '~/classes/Token';

export const isSignIn = () => {
  Token.instance.loadTokenFromStorage();

  return !!Token.instance.accessToken;
};

export const getDecodedFileName = (url: string) => {
  return decodeURIComponent(url.split('?')[0].split(`${process.env.REACT_APP_S3_URL}`)[1]);
};

export const getKeyFromUrl = (url: string) => {
  const index = url.indexOf('?');

  return url.substring(0, index);
};

export const secondsToFormattedTime = (seconds: number, withZero: boolean = true) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (!withZero) {
    let result = h === 0 ? '' : `${h}시간`;
    if (m > 0) result += ` ${m}분`;

    if (h === 0 && m === 0 && seconds !== 0) return `${Math.round(seconds)}초`;

    return result.trim();
  }

  return h === 0 ? `${m}분` : `${h}시간 ${m}분`;
};
