import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import AuthAPI from '~/api/AuthAPI';
import { Token } from '~/classes/Token';
import { Gender, Role, User } from '~/classes/User';
import { firebaseAuth, googlePopup, googleProvider } from '~/helper/firebase';
import { isSignIn } from '~/utils';

export const signIn = createAsyncThunk('auth/signIn', async (_, { rejectWithValue }) => {
  try {
    const userCredential = await googlePopup(firebaseAuth, googleProvider);
    const firebaseIdToken = await userCredential.user.getIdToken();

    const { user, token } = await AuthAPI.signIn({ idToken: firebaseIdToken });

    if (user.roles.includes(Role.ADMIN)) {
      Token.instance.setStorage(token);

      return user;
    }

    toast.error('No Administrator Account!');
    throw new Error('No Admin!');
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

export const load = createAsyncThunk('auth/load', async (_, { rejectWithValue }) => {
  try {
    if (isSignIn()) {
      const user = await AuthAPI.load();

      if (user.roles.includes(Role.ADMIN)) return user;

      toast.error('No Administrator Account!');
      throw new Error('No Admin!');
    }

    throw new Error('No Token!');
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

interface AuthState {
  isTokenLoaded: boolean;
  isSignIn: boolean;
  me: User;
}

const initialState: AuthState = {
  isTokenLoaded: false,
  isSignIn: isSignIn(),
  me: {
    _id: '',
    email: '',
    nickname: '',
    picture: '',
    gender: Gender.FEMALE,
    roles: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  },
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signout: (state) => {
      state.isSignIn = false;
      state.me = initialState.me;

      Token.instance.removeStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending.type, () => {})
      .addCase(signIn.fulfilled.type, (state, { payload: user }: PayloadAction<User>) => {
        state.isSignIn = true;
        state.me = user;
      })
      .addCase(signIn.rejected.type, (state) => {
        state.isSignIn = false;
        state.me = initialState.me;
      })
      .addCase(load.pending.type, (state) => {
        state.isTokenLoaded = true;
      })
      .addCase(load.fulfilled.type, (state, { payload: user }: PayloadAction<User>) => {
        state.isTokenLoaded = false;
        state.isSignIn = true;
        state.me = user;
      })
      .addCase(load.rejected.type, (state) => {
        state.isTokenLoaded = false;
        state.isSignIn = false;
        state.me = initialState.me;
      });
  },
});

export const signout = auth.actions.signout;

export default auth.reducer;
