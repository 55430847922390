import { useLayoutEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import Path from './constants/Path';
import { useDispatch, useSelector } from './hooks/useRedux';
import Categories from './pages/Categories';
import Devices from './pages/Devices';
import Home from './pages/Home';
import Loading from './pages/Loading';
import Programs from './pages/Programs';
import Sessions from './pages/Sessions';
import SignIn from './pages/SignIn';
import Users from './pages/Users';
import { load } from './reducers/auth';

const App = () => {
  const dispatch = useDispatch();

  const { isTokenLoaded, isSignIn } = useSelector((state) => state.auth);

  useLayoutEffect(() => {
    dispatch(load());
  }, [dispatch]);

  if (isTokenLoaded) return <Loading />;

  return (
    <Router>
      <Routes>
        <Route
          element={
            <ProtectedRoute isValid={isSignIn} withLayout={true} redirectPath={Path.SIGN_IN} />
          }
        >
          <Route path={Path.HOME} element={<Home />} />
          <Route path={Path.USERS} element={<Users />} />
          <Route path={Path.DEVICES} element={<Devices />} />
          <Route path={Path.CATEGORIES} element={<Categories />} />
          <Route path={Path.PROGRAMS} element={<Programs />} />
          <Route path={Path.SESSIONS} element={<Sessions />} />
        </Route>

        <Route
          element={
            <ProtectedRoute isValid={!isSignIn} withLayout={false} redirectPath={Path.HOME} />
          }
        >
          <Route path={Path.SIGN_IN} element={<SignIn />} />
        </Route>
        <Route path={'/*'} element={<Navigate to={Path.SIGN_IN} />} />
      </Routes>
    </Router>
  );
};

export default App;
