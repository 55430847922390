import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/es/table';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetSessionsQuery } from '~/api/SessionAPI';
import Pagination from '~/classes/Pagination';
import { Session } from '~/classes/Session';
import DisabledTag from '~/components/DisabledTag';
import { Space } from '~/components/Space';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Text16Bold, Text20Bold } from '~/styles/Texts';
import { Column, Row, SpaceBetween } from '~/styles/Wrappers';
import { secondsToFormattedTime } from '~/utils';

import DeleteModal from './DeleteModal';
import SessionModal from './SessionModal';

const Sessions = () => {
  const { programId } = useParams();

  const columns: ColumnsType<Session> = [
    { key: 'title', title: '세션 이름', dataIndex: 'title' },
    { key: 'narrator', title: '나레이터', dataIndex: 'narrator' },
    {
      key: 'preDuration',
      title: '프리 길이',
      dataIndex: 'preDuration',
      render: (preDuration) => <>{secondsToFormattedTime(preDuration, false)}</>,
    },
    {
      key: 'duration',
      title: '길이',
      dataIndex: 'duration',
      render: (duration) => <>{secondsToFormattedTime(duration)}</>,
    },
    {
      key: 'background',
      title: '배경',
      dataIndex: 'background',
      render: (background) => (
        <Column>
          <video
            style={{ width: 110 }}
            src={`${process.env.REACT_APP_S3_URL}${background}`}
            autoPlay={false}
            controls
          />
          <>{background.split('/')[1]}</>
        </Column>
      ),
    },
    {
      key: 'preAudio',
      title: '프리 오디오',
      dataIndex: 'preAudio',
      render: (preAudio) => (
        <Column>
          <audio
            style={{ width: 220 }}
            src={`${process.env.REACT_APP_S3_URL}${preAudio}`}
            autoPlay={false}
            controls
          />
          <>{preAudio.split('/')[1]}</>
        </Column>
      ),
    },
    {
      key: 'audio',
      title: '오디오',
      dataIndex: 'audio',
      render: (audio) => (
        <Column>
          <audio
            style={{ width: 220 }}
            src={`${process.env.REACT_APP_S3_URL}${audio}`}
            autoPlay={false}
            controls
          />
          <>{audio.split('/')[1]}</>
        </Column>
      ),
    },
    {
      key: 'disabled',
      title: '비활성화',
      dataIndex: 'disabled',
      render: (disabled) => <DisabledTag disabled={disabled} />,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
    {
      key: 'update',
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            setSessionId(record._id);
            setSessionModalOpen(true);
          }}
        />
      ),
    },
    {
      key: 'delete',
      render: (_, record) => (
        <DeleteOutlined
          onClick={() => {
            setSessionId(record._id);
            setDeleteModalOpen(true);
          }}
        />
      ),
    },
  ];

  const [pagination, setPage, setLimit] = usePaginationRequest();

  const { values, handleChange, handleSubmit } = useFormik<{ title: string; narrator: string }>({
    initialValues: {
      title: '',
      narrator: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string(),
      narrator: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const [sessionId, setSessionId] = useState('');
  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data = new Pagination<Session>(), refetch } = useGetSessionsQuery({
    ...pagination,
    programId,
    title: values.title || undefined,
    narrator: values.narrator || undefined,
  });

  useEffect(() => {
    refetch();
  }, [sessionModalOpen, deleteModalOpen, refetch]);

  return (
    <Column flex={1}>
      <Text16Bold>프로그램명: {data.data[0]?.program?.title}</Text16Bold>

      <SpaceBetween>
        <Row>
          <Text20Bold>세션</Text20Bold>
          <Space width={12} />

          <PlusCircleOutlined
            onClick={() => {
              setSessionId('');
              setSessionModalOpen(true);
            }}
          />
        </Row>
        <Search
          name="title"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.title}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `총 세션 ${total} 개`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
      />

      <SessionModal
        programId={programId!}
        sessionId={sessionId}
        sessionModalOpen={sessionModalOpen}
        setSessionModalOpen={setSessionModalOpen}
      />
      <DeleteModal
        sessionId={sessionId}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    </Column>
  );
};

export default Sessions;
