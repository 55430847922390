import { createApi } from '@reduxjs/toolkit/query/react';

import { Category } from '~/classes/Category';
import Pagination from '~/classes/Pagination';
import { CategoryDto } from '~/classes/dto/CategoryDto';
import { SearchCategoryDto } from '~/classes/dto/SearchCategoryDto';
import { axiosBaseQuery } from '~/helper/axios';

export const categoryAPI = createApi({
  reducerPath: 'categoryAPI',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCategory: builder.query<Category, string>({
      query: (_id) => ({ url: `/category/${_id}`, method: 'get' }),
      transformResponse: (category: Category) => new Category(category),
    }),
    getCategories: builder.query<Pagination<Category>, SearchCategoryDto>({
      query: (params) => ({ url: '/category', method: 'get', params }),
      transformResponse: (data: Pagination<Category>) => new Pagination(data, Category),
    }),
    createCategory: builder.mutation<void, CategoryDto>({
      query: (data) => ({ url: '/category/admin', method: 'post', data }),
    }),
    updateCategory: builder.mutation<void, CategoryDto>({
      query: (data) => ({ url: '/category/admin', method: 'patch', data }),
    }),
    deleteCategory: builder.mutation<void, string>({
      query: (_id) => ({ url: `/category/admin/${_id}`, method: 'delete' }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetCategoryQuery,
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryAPI;

export default {};
