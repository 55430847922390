import { Image, Button } from 'antd';
import { Header as HeaderDefault } from 'antd/es/layout/layout';
import { useCallback } from 'react';

import Logo from '~/assets/svg/logo.svg';
import Color from '~/constants/Color';
import { useDispatch } from '~/hooks/useRedux';
import { signout } from '~/reducers/auth';
import { Text32Bold } from '~/styles/Texts';
import { Row } from '~/styles/Wrappers';

const Header = () => {
  const dispatch = useDispatch();

  const handleSignout = useCallback(() => {
    dispatch(signout());
  }, [dispatch]);

  return (
    <HeaderDefault
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '10%',
        backgroundColor: Color.CFF453A,
      }}
    >
      <Row alignItems="center">
        <Image src={Logo} width={40} height={40} />
        <Text32Bold style={{ marginLeft: 10 }} color={Color.CFFFFFF}>
          Shine
        </Text32Bold>
      </Row>

      <Button onClick={handleSignout}>로그아웃</Button>
    </HeaderDefault>
  );
};

export default Header;
