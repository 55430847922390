import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10 * 1000,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error.response?.data || error.message);

    return Promise.reject(error);
  },
);

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    SerializedError
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url, method, data, params });

      return { data: result.data };
    } catch (err) {
      const axiosError = err as AxiosError<{ message: string }>;

      return Promise.reject(new Error(axiosError.response?.data?.message || axiosError.message));
    }
  };

export default axios;
