import { configureStore } from '@reduxjs/toolkit';

import { categoryAPI } from '~/api/CategoryAPI';
import { deviceAPI } from '~/api/DeviceAPI';
import { programAPI } from '~/api/ProgramAPI';
import { s3ManagerAPI } from '~/api/S3ManagerAPI';
import { sessionAPI } from '~/api/SessionAPI';
import { userAPI } from '~/api/UserAPI';

import authReducer from './auth';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [categoryAPI.reducerPath]: categoryAPI.reducer,
    [programAPI.reducerPath]: programAPI.reducer,
    [sessionAPI.reducerPath]: sessionAPI.reducer,
    [deviceAPI.reducerPath]: deviceAPI.reducer,
    [s3ManagerAPI.reducerPath]: s3ManagerAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userAPI.middleware,
      categoryAPI.middleware,
      programAPI.middleware,
      sessionAPI.middleware,
      deviceAPI.middleware,
      s3ManagerAPI.middleware,
    ),
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
