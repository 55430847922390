import { Modal } from 'antd';
import { useState } from 'react';

import { useDeleteSessionMutation, useGetSessionQuery } from '~/api/SessionAPI';
import { Text16 } from '~/styles/Texts';

interface Props {
  sessionId: string;
  deleteModalOpen: boolean;
  setDeleteModalOpen: (deleteModalOpen: boolean) => void;
}

const DeleteModal = ({ sessionId, deleteModalOpen, setDeleteModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { data } = useGetSessionQuery(sessionId);
  const [deleteSession] = useDeleteSessionMutation();

  const handleDelete = async () => {
    setIsConfirmLoading(true);

    await deleteSession(sessionId);

    setIsConfirmLoading(false);
    setDeleteModalOpen(false);
  };

  return (
    <Modal
      title="세션 삭제"
      open={deleteModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleDelete()}
      onCancel={() => setDeleteModalOpen(false)}
    >
      <Text16>타이틀: {data?.title}</Text16>
    </Modal>
  );
};

export default DeleteModal;
