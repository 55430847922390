import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDeUXr7P2fF6W2cTXCy7bxp1StaHbn-FLU',
  authDomain: 'hanwha-femtech.firebaseapp.com',
  projectId: 'hanwha-femtech',
  storageBucket: 'hanwha-femtech.appspot.com',
  messagingSenderId: '663307862607',
  appId: '1:663307862607:web:2a647b0f30edc0f6fb603a',
  measurementId: 'G-4N7Y7PHWHR',
};

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);
export const googleProvider = new GoogleAuthProvider();
export const googlePopup = signInWithPopup;
