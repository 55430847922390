import { createApi } from '@reduxjs/toolkit/query/react';

import Pagination from '~/classes/Pagination';
import PaginationRequest from '~/classes/PaginationRequest';
import { Program } from '~/classes/Program';
import { ProgramDto } from '~/classes/dto/ProgramDto';
import { SearchProgramDto } from '~/classes/dto/SearchProgramDto';
import { axiosBaseQuery } from '~/helper/axios';

export const programAPI = createApi({
  reducerPath: 'programAPI',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getProgram: builder.query<Program, string>({
      query: (_id) => ({ url: `/program/${_id}`, method: 'get' }),
      transformResponse: (program: Program) => new Program(program),
    }),
    getPrograms: builder.query<Pagination<Program>, SearchProgramDto>({
      query: (params) => ({ url: '/program', method: 'get', params }),
      transformResponse: (data: Pagination<Program>) => new Pagination(data, Program),
    }),
    getProgressProgram: builder.query<Program, void>({
      query: () => ({ url: '/program/progress', method: 'get' }),
      transformResponse: (program: Program) => new Program(program),
    }),
    getLikePrograms: builder.query<Pagination<Program>, PaginationRequest>({
      query: (params) => ({ url: '/program/like', method: 'get', params }),
      transformResponse: (data: Pagination<Program>) => new Pagination(data, Program),
    }),
    getRecentlyLookedPrograms: builder.query<Pagination<Program>, PaginationRequest>({
      query: (params) => ({ url: '/program/recently-looked', method: 'get', params }),
      transformResponse: (data: Pagination<Program>) => new Pagination(data, Program),
    }),
    getPopularPrograms: builder.query<Pagination<Program>, PaginationRequest>({
      query: (params) => ({ url: '/program/popular', method: 'get', params }),
      transformResponse: (data: Pagination<Program>) => new Pagination(data, Program),
    }),
    getRecommendedPrograms: builder.query<Pagination<Program>, PaginationRequest>({
      query: (params) => ({ url: '/program/recommended', method: 'get', params }),
      transformResponse: (data: Pagination<Program>) => new Pagination(data, Program),
    }),
    createProgram: builder.mutation<void, ProgramDto>({
      query: (data) => ({ url: '/program/admin', method: 'post', data }),
    }),
    updateProgram: builder.mutation<void, ProgramDto>({
      query: (data) => ({ url: '/program/admin', method: 'patch', data }),
    }),
    deleteProgram: builder.mutation<void, string>({
      query: (_id) => ({ url: `/program/admin/${_id}`, method: 'delete' }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetProgramQuery,
  useGetProgramsQuery,
  useGetProgressProgramQuery,
  useGetLikeProgramsQuery,
  useGetRecentlyLookedProgramsQuery,
  useGetPopularProgramsQuery,
  useGetRecommendedProgramsQuery,
  useCreateProgramMutation,
  useUpdateProgramMutation,
  useDeleteProgramMutation,
} = programAPI;

export default {};
