import { Device } from './Device';

export enum Role {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export enum GenderText {
  FEMALE = '여성',
  MALE = '남성',
  OTHER = '기타',
}

export enum UserType {
  ROAMER = 'ROAMER', // 방랑자
  SPARK = 'SPARK', // 스파크
  ADVENTURER = 'ADVENTURER', // 승부사
  MODESTY = 'MODESTY', // 겸손
  ACTIVIST = 'ACTIVIST', // 활동가
  WATCHER = 'WATCHER', // 당직자
  SELF_MANAGEMENT = 'SELF_MANAGEMENT', // 자기관리
  ENTROPY = 'ENTROPY', // 엔트로피
}

export enum UserTypeText {
  ROAMER = '방랑자',
  SPARK = '스파크',
  ADVENTURER = '승부사',
  MODESTY = '겸손',
  ACTIVIST = '활동가',
  WATCHER = '당직자',
  SELF_MANAGEMENT = '자기관리',
  ENTROPY = '엔트로피',
}

export class User {
  _id: string;
  email: string;
  nickname: string;
  picture: string;
  gender: Gender;
  userType?: UserType;
  weekdayAlarmList?: string[];
  weekdayAlarmTime?: string;
  weekendAlarmList?: string[];
  weekendAlarmTime?: string;
  menstrualDuration?: number;
  roles: Role[];
  deviceId?: string;
  device?: Device;
  createdAt: Date;
  updatedAt: Date;

  constructor(user: User) {
    this._id = user._id;
    this.email = user.email;
    this.nickname = user.nickname;
    this.picture = user.picture;
    this.gender = user.gender;
    this.userType = user.userType;
    this.weekdayAlarmList = user.weekdayAlarmList || [];
    this.weekdayAlarmTime = user.weekdayAlarmTime || '07:00';
    this.weekendAlarmList = user.weekendAlarmList || [];
    this.weekendAlarmTime = user.weekendAlarmTime || '07:00';
    this.menstrualDuration = user.menstrualDuration || 28;
    this.roles = user.roles;
    this.deviceId = user.deviceId;
    this.device = user.device && new Device(user.device);
    this.createdAt = new Date(user.createdAt);
    this.updatedAt = new Date(user.updatedAt);
  }
}
