import { Modal } from 'antd';
import { useState } from 'react';

import { useDeleteCategoryMutation, useGetCategoryQuery } from '~/api/CategoryAPI';
import { Text16 } from '~/styles/Texts';

interface Props {
  categoryId: string;
  deleteModalOpen: boolean;
  setDeleteModalOpen: (deleteModalOpen: boolean) => void;
}

const DeleteModal = ({ categoryId, deleteModalOpen, setDeleteModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { data } = useGetCategoryQuery(categoryId);
  const [deleteCategory] = useDeleteCategoryMutation();

  const handleDelete = async () => {
    setIsConfirmLoading(true);

    await deleteCategory(categoryId);

    setIsConfirmLoading(false);
    setDeleteModalOpen(false);
  };

  return (
    <Modal
      title="카테고리 삭제"
      open={deleteModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleDelete()}
      onCancel={() => setDeleteModalOpen(false)}
    >
      <Text16>타이틀: {data?.title}</Text16>
    </Modal>
  );
};

export default DeleteModal;
