import { createApi } from '@reduxjs/toolkit/query/react';

import Pagination from '~/classes/Pagination';
import { User } from '~/classes/User';
import { SearchUserDto } from '~/classes/dto/SearchUserDto';
import { UserDto } from '~/classes/dto/UserDto';
import { axiosBaseQuery } from '~/helper/axios';

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query: (_id) => ({ url: `/user/users/${_id}`, method: 'get' }),
      transformResponse: (user: User) => new User(user),
    }),
    getUsers: builder.query<Pagination<User>, SearchUserDto>({
      query: (params) => ({
        url: '/user/users',
        method: 'GET',
        params,
      }),
      transformResponse: (data: Pagination<User>) => new Pagination(data, User),
    }),
    updateUser: builder.mutation<void, UserDto>({
      query: (data) => ({ url: '/user/profile-admin', method: 'patch', data }),
    }),
  }),

  refetchOnMountOrArgChange: true,
});

export const { useGetUserQuery, useGetUsersQuery, useUpdateUserMutation } = userAPI;
