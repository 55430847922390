import { createApi } from '@reduxjs/toolkit/query/react';
import Axios from 'axios';

import { axiosBaseQuery } from '~/helper/axios';

const putObject = async (url: string, file: File): Promise<void> => {
  await Axios.put(url, file, {
    headers: { 'Content-Type': 'application/octet-stream' },
  });
};

export const s3ManagerAPI = createApi({
  reducerPath: 's3ManagerAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createS3Url: builder.mutation<{ url: string }, { key: string }>({
      query: (data) => ({
        url: '/s3-manager/upload-url',
        method: 'POST',
        data,
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export default { putObject };

export const { useCreateS3UrlMutation } = s3ManagerAPI;
