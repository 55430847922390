import { Checkbox, Form, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  useCreateSessionMutation,
  useGetSessionQuery,
  useUpdateSessionMutation,
} from '~/api/SessionAPI';
import { SessionDto } from '~/classes/dto/SessionDto';
import useS3Handler from '~/hooks/useS3Handler';
import { Column } from '~/styles/Wrappers';

interface Props {
  programId: string;
  sessionId: string;
  sessionModalOpen: boolean;
  setSessionModalOpen: (sessionModalOpen: boolean) => void;
}

const SessionModal = ({ programId, sessionId, sessionModalOpen, setSessionModalOpen }: Props) => {
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<SessionDto>({
    initialValues: {
      programId,
      title: '',
      narrator: '',
      background: '',
      preAudio: '',
      audio: '',
      disabled: false,
    },
    validationSchema: Yup.object().shape({
      programId: Yup.string().required(),
      title: Yup.string().required(),
      narrator: Yup.string().required(),
      background: Yup.string().required(),
      preAudio: Yup.string().required(),
      audio: Yup.string().required(),
      disabled: Yup.boolean().required(),
    }),
    onSubmit: async (data: SessionDto) => {
      setIsConfirmLoading(true);

      if (sessionId) {
        await updateSession({ ...data, _id: sessionId });
      } else {
        await createSession(data);
      }

      setIsConfirmLoading(false);
      setSessionModalOpen(false);
    },
  });

  const { data } = useGetSessionQuery(sessionId);
  const [isLoading, setIsLoading] = useState(false);
  const [createSession] = useCreateSessionMutation();
  const [updateSession] = useUpdateSessionMutation();

  const getFileName = useS3Handler();

  const handleS3Upload = async (event) => {
    setIsLoading(true);
    const fileName = await getFileName(event, 'session/');

    setFieldValue(event.target.name, fileName);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!sessionId) return resetForm();

    if (data) {
      setFieldValue('programId', data.programId);
      setFieldValue('title', data.title);
      setFieldValue('narrator', data.narrator);
      setFieldValue('background', data.background);
      setFieldValue('preAudio', data.preAudio);
      setFieldValue('audio', data.audio);
      setFieldValue('disabled', data.disabled);
    }
  }, [data, resetForm, sessionId, setFieldValue]);

  return (
    <Modal
      title={sessionId ? '세션 수정' : '새로운 세션 생성'}
      open={sessionModalOpen}
      confirmLoading={isConfirmLoading}
      onOk={() => handleSubmit()}
      okButtonProps={{ disabled: isLoading }}
      onCancel={() => setSessionModalOpen(false)}
      destroyOnClose
    >
      <Form labelCol={{ span: 5 }}>
        <Form.Item label="타이틀">
          <Input name="title" value={values.title} onChange={handleChange} />
        </Form.Item>

        <Form.Item label="나레이터">
          <Input name="narrator" value={values.narrator} onChange={handleChange} />
        </Form.Item>

        <Form.Item label="배경">
          <Column>
            {values.background && (
              <video
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_S3_URL}${values.background}`}
                controls
              />
            )}
            <input type="file" accept="video/*" name="background" onChange={handleS3Upload} />
          </Column>
        </Form.Item>

        <Form.Item label="프리 오디오">
          <Column>
            {values.preAudio && (
              <audio
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_S3_URL}${values.preAudio}`}
                controls
              />
            )}
            <input type="file" accept="audio/*" name="preAudio" onChange={handleS3Upload} />
          </Column>
        </Form.Item>

        <Form.Item label="오디오">
          <Column>
            {values.audio && (
              <audio
                style={{ width: '100%' }}
                src={`${process.env.REACT_APP_S3_URL}${values.audio}`}
                controls
              />
            )}
            <input type="file" accept="audio/*" name="audio" onChange={handleS3Upload} />
          </Column>
        </Form.Item>

        <Form.Item label="비활성화" valuePropName="checked">
          <Checkbox name="disabled" checked={values.disabled} onChange={handleChange}>
            비활성화
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SessionModal;
