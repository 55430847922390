import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/es/table';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useGetDevicesQuery } from '~/api/DeviceAPI';
import { Device } from '~/classes/Device';
import Pagination from '~/classes/Pagination';
import { Space } from '~/components/Space';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Column, Expanded, SpaceBetween } from '~/styles/Wrappers';

const Devices = () => {
  const columns: ColumnsType<Device> = [
    { key: 'deviceName', title: '디바이스 이름', dataIndex: 'deviceName' },
    {
      key: 'createdAt',
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt) => <>{createdAt.toLocaleDateString()}</>,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
  ];

  const [pagination, setPage, setLimit] = usePaginationRequest();

  const { values, handleChange, handleSubmit } = useFormik<{ deviceName: string }>({
    initialValues: {
      deviceName: '',
    },
    validationSchema: Yup.object().shape({
      deviceName: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const { data = new Pagination<Device>(), refetch } = useGetDevicesQuery({
    ...pagination,
    deviceName: values.deviceName || undefined,
  });

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Expanded />
        <Search
          name="deviceName"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.deviceName}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `총 디바이스 ${total} 개`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
      />
    </Column>
  );
};

export default Devices;
