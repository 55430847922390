import { createApi } from '@reduxjs/toolkit/query/react';

import { Device } from '~/classes/Device';
import Pagination from '~/classes/Pagination';
import { SearchDeviceDto } from '~/classes/dto/SearchDeviceDto';
import { axiosBaseQuery } from '~/helper/axios';

export const deviceAPI = createApi({
  reducerPath: 'deviceAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getDevices: builder.query<Pagination<Device>, SearchDeviceDto>({
      query: (params) => ({
        url: '/device',
        method: 'GET',
        params,
      }),
      transformResponse: (data: Pagination<Device>) => new Pagination(data, Device),
    }),
    getDevice: builder.query<Device, string>({
      query: (deviceName) => ({
        url: `/device/${deviceName}`,
        method: 'GET',
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const { useGetDevicesQuery, useGetDeviceQuery } = deviceAPI;
