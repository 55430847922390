import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/es/table';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetCategoriesQuery } from '~/api/CategoryAPI';
import { Category } from '~/classes/Category';
import Pagination from '~/classes/Pagination';
import DisabledTag from '~/components/DisabledTag';
import { Space } from '~/components/Space';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Text20Bold } from '~/styles/Texts';
import { Column, Row, SpaceBetween } from '~/styles/Wrappers';

import CategoryModal from './CategoryModal';
import DeleteModal from './DeleteModal';

const Categories = () => {
  const navigate = useNavigate();

  const columns: ColumnsType<Category> = [
    { key: 'title', title: '카테고리 이름', dataIndex: 'title' },
    { key: 'category', title: '카테고리', dataIndex: 'category' },
    {
      key: 'image',
      title: '이미지',
      dataIndex: 'image',
      render: (image) => (
        <Column>
          <Image
            style={{ width: 100 }}
            src={`${process.env.REACT_APP_S3_URL}${image}`}
            alt={image}
            preview={false}
          />
          <>{image.split('/')[1]}</>
        </Column>
      ),
    },
    {
      key: 'expandedImage',
      title: '확장된 이미지',
      dataIndex: 'expandedImage',
      render: (expandedImage) => (
        <Column>
          <Image
            style={{ width: 120 }}
            src={`${process.env.REACT_APP_S3_URL}${expandedImage}`}
            alt={expandedImage}
            preview={false}
          />
          <>{expandedImage.split('/')[1]}</>
        </Column>
      ),
    },
    {
      key: 'disabled',
      title: '비활성화',
      dataIndex: 'disabled',
      render: (disabled) => <DisabledTag disabled={disabled} />,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
    {
      key: 'update',
      render: (_, record) => (
        <EditOutlined
          onClick={(e) => {
            e.stopPropagation();
            setCategoryId(record._id);
            setCategoryModalOpen(true);
          }}
        />
      ),
    },
    {
      key: 'delete',
      render: (_, record) => (
        <DeleteOutlined
          onClick={(e) => {
            e.stopPropagation();
            setCategoryId(record._id);
            setDeleteModalOpen(true);
          }}
        />
      ),
    },
  ];

  const [pagination, setPage, setLimit] = usePaginationRequest();

  const { values, handleChange, handleSubmit } = useFormik<{ title: string }>({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const [categoryId, setCategoryId] = useState('');
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data = new Pagination<Category>(), refetch } = useGetCategoriesQuery({
    ...pagination,
    title: values.title || undefined,
  });

  useEffect(() => {
    refetch();
  }, [categoryModalOpen, deleteModalOpen, refetch]);

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Row>
          <Text20Bold>카테고리</Text20Bold>
          <Space width={12} />

          <PlusCircleOutlined
            onClick={() => {
              setCategoryId('');
              setCategoryModalOpen(true);
            }}
          />
        </Row>
        <Search
          name="title"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.title}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ cursor: 'pointer', overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `총 카테고리 ${total} 개`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
        onRow={(category) => ({
          onClick: () => navigate(`/categories/${category._id}/programs`),
        })}
      />

      <CategoryModal
        categoryId={categoryId}
        categoryModalOpen={categoryModalOpen}
        setCategoryModalOpen={setCategoryModalOpen}
      />
      <DeleteModal
        categoryId={categoryId}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    </Column>
  );
};

export default Categories;
