import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/es/table';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetProgramsQuery } from '~/api/ProgramAPI';
import Pagination from '~/classes/Pagination';
import { Program } from '~/classes/Program';
import DisabledTag from '~/components/DisabledTag';
import { Space } from '~/components/Space';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Text16Bold, Text20Bold } from '~/styles/Texts';
import { Column, Row, SpaceBetween } from '~/styles/Wrappers';
import { secondsToFormattedTime } from '~/utils';

import DeleteModal from './DeleteModal';
import ProgramModal from './ProgramModal';

const Programs = () => {
  const navigate = useNavigate();

  const { categoryId } = useParams();

  const columns: ColumnsType<Program> = [
    { key: 'title', title: '프로그램 이름', dataIndex: 'title' },
    { key: 'narrator', title: '나레이터', dataIndex: 'narrator' },
    {
      key: 'duration',
      title: '길이',
      dataIndex: 'duration',
      render: (duration) => <>{secondsToFormattedTime(duration)}</>,
    },
    {
      key: 'image',
      title: '이미지',
      dataIndex: 'image',
      render: (image) => (
        <Column>
          <Image
            style={{ width: 100 }}
            src={`${process.env.REACT_APP_S3_URL}${image}`}
            alt={image}
            preview={false}
          />
          <>{image.split('/')[1]}</>
        </Column>
      ),
    },
    {
      key: 'disabled',
      title: '비활성화',
      dataIndex: 'disabled',
      render: (disabled) => <DisabledTag disabled={disabled} />,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
    {
      key: 'update',
      render: (_, record) => (
        <EditOutlined
          onClick={(e) => {
            e.stopPropagation();
            setProgramId(record._id);
            setProgramModalOpen(true);
          }}
        />
      ),
    },
    {
      key: 'delete',
      render: (_, record) => (
        <DeleteOutlined
          onClick={(e) => {
            e.stopPropagation();
            setProgramId(record._id);
            setDeleteModalOpen(true);
          }}
        />
      ),
    },
  ];

  const [pagination, setPage, setLimit] = usePaginationRequest();

  const { values, handleChange, handleSubmit } = useFormik<{ title: string; narrator: string }>({
    initialValues: {
      title: '',
      narrator: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string(),
      narrator: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const [programId, setProgramId] = useState('');
  const [programModalOpen, setProgramModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data = new Pagination<Program>(), refetch } = useGetProgramsQuery({
    ...pagination,
    categoryId,
    title: values.title || undefined,
    narrator: values.narrator || undefined,
  });

  useEffect(() => {
    refetch();
  }, [programModalOpen, deleteModalOpen, refetch]);

  return (
    <Column flex={1}>
      <Text16Bold>카테고리명: {data.data[0]?.category?.title}</Text16Bold>

      <SpaceBetween>
        <Row>
          <Text20Bold>프로그램</Text20Bold>
          <Space width={12} />

          <PlusCircleOutlined
            onClick={() => {
              setProgramId('');
              setProgramModalOpen(true);
            }}
          />
        </Row>
        <Search
          name="title"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.title}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ cursor: 'pointer', overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `총 프로그램 ${total} 개`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
        onRow={(program) => ({
          onClick: () => navigate(`/categories/${categoryId}/programs/${program._id}/sessions`),
        })}
      />

      <ProgramModal
        categoryId={categoryId!}
        programId={programId}
        programModalOpen={programModalOpen}
        setProgramModalOpen={setProgramModalOpen}
      />
      <DeleteModal
        programId={programId}
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
      />
    </Column>
  );
};

export default Programs;
