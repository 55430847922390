import { Program } from './Program';

export class Session {
  _id: string;
  programId: string;
  program?: Program;
  title: string;
  narrator: string;
  preDuration: number;
  duration: number;
  background: string;
  preAudio: string;
  audio: string;
  disabled: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(session: Session) {
    this._id = session._id;
    this.programId = session.programId;
    this.program = session.program && new Program(session.program);
    this.title = session.title;
    this.narrator = session.narrator;
    this.preDuration = session.preDuration;
    this.duration = session.duration;
    this.background = session.background;
    this.preAudio = session.preAudio;
    this.audio = session.audio;
    this.disabled = session.disabled;
    this.createdAt = new Date(session.createdAt);
    this.updatedAt = new Date(session.updatedAt);
  }
}
