import { Global, css } from '@emotion/react';

import Color from '~/constants/Color';

const GlobalStyle = () => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: 'border-box';
        font-family: 'Noto Sans KR';
        font-size: '100%';
        font-weight: 'normal';
        font-style: 'normal';
        line-height: normal;
        vertical-align: 'baseline';
      }
      .ant-menu-item-selected {
        background-color: ${Color.CFF453A30} !important;
        color: ${Color.C000000} !important;
      }
      .ant-pagination .ant-pagination-item a {
        display: inline-block;
      }
    `}
  />
);

export default GlobalStyle;
