import { EditOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import Search from 'antd/es/input/Search';
import Table, { ColumnsType } from 'antd/es/table';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useGetUsersQuery } from '~/api/UserAPI';
import DefaultProfile from '~/assets/svg/default_profile.svg';
import Pagination from '~/classes/Pagination';
import { GenderText, User, UserTypeText } from '~/classes/User';
import { Space } from '~/components/Space';
import usePaginationRequest from '~/hooks/usePaginationRequest';
import { Column, Expanded, SpaceBetween } from '~/styles/Wrappers';

import UserModal from './UserModal';

const Users = () => {
  const columns: ColumnsType<User> = [
    {
      key: 'picture',
      title: '프로필',
      dataIndex: 'picture',
      render: (picture) => (
        <Image
          style={{ borderRadius: 50 }}
          src={picture}
          width={40}
          height={40}
          fallback={DefaultProfile}
        />
      ),
    },
    { key: 'nickname', title: '닉네임', dataIndex: 'nickname' },
    { key: 'email', title: '이메일', dataIndex: 'email' },
    {
      key: 'gender',
      title: '성별',
      dataIndex: 'gender',
      render: (gender) => <>{GenderText[gender]}</>,
    },
    {
      key: 'userType',
      title: '유저 타입',
      dataIndex: 'userType',
      render: (userType) => <>{UserTypeText[userType]}</>,
    },
    {
      key: 'device',
      title: '디바이스 이름',
      dataIndex: 'device',
      render: (device) => <>{device?.deviceName || ''}</>,
    },
    {
      key: 'createdAt',
      title: '생성일',
      dataIndex: 'createdAt',
      render: (createdAt) => <>{createdAt.toLocaleDateString()}</>,
    },
    {
      key: 'updatedAt',
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (updatedAt) => <>{updatedAt.toLocaleDateString()}</>,
    },
    {
      key: 'update',
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            setUserId(record._id);
            setUserModalOpen(true);
          }}
        />
      ),
    },
  ];
  const [pagination, setPage, setLimit] = usePaginationRequest();

  const { values, handleChange, handleSubmit } = useFormik<{ keyword: string }>({
    initialValues: {
      keyword: '',
    },
    validationSchema: Yup.object().shape({
      keyword: Yup.string(),
    }),
    onSubmit: async () => {
      setPage(1);

      refetch();
    },
  });

  const [userId, setUserId] = useState('');
  const [userModalOpen, setUserModalOpen] = useState(false);

  const { data = new Pagination<User>(), refetch } = useGetUsersQuery({
    ...pagination,
    keyword: values.keyword || undefined,
  });

  useEffect(() => {
    refetch();
  }, [userModalOpen, refetch]);

  return (
    <Column flex={1}>
      <SpaceBetween>
        <Expanded />
        <Search
          name="keyword"
          style={{ width: 200 }}
          placeholder="검색어를 입력하세요"
          value={values.keyword}
          onChange={handleChange}
          onSearch={() => handleSubmit()}
          allowClear
        />
      </SpaceBetween>
      <Space height={12} />

      <Table
        style={{ overflow: 'scroll' }}
        rowKey={({ _id }) => _id}
        columns={columns}
        dataSource={data.data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          showSizeChanger: true,
          total: data.count,
          showTotal: (total) => `총 유저 ${total} 명`,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        scroll={{
          x: true,
        }}
      />

      <UserModal
        userId={userId}
        userModalOpen={userModalOpen}
        setUserModalOpen={setUserModalOpen}
      />
    </Column>
  );
};

export default Users;
